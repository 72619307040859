.device-pagination {
  font-family: "Honeywell Sans Web";
  padding-bottom: 5px;
  margin-top: 5px !important;
}

/* added */
.classNAme em {
  color: #1792e5;
  padding-left: 10px;
  padding-top: 2px;
}

/*added*/
.classNAme {
  display: flex;
}

.filter-drawer{
  z-index: 1300 !important;
}
.filter-drawer .MuiInputLabel-shrink.select-title{
  display: none;
}
.select-title{
  margin-top: -7px;
}
.MuiInputLabel-shrink.select-title{
  margin: 2px !important;
}

.errorComp.showDetailsDataError {
  background-color: #272727;
}
.errorComp.showDetailsDataError .errorCompContent {
  font-size: 22px;
}

.errorComp.showDetailsDataError .DataErrorBtn {
  border-radius: 4px;
  margin: 20px 1em 10px 10px;
  cursor: pointer;
  font-weight: 600;
  background-color: rgb(100, 195, 255);
  color: #000 !important;
  border: none;
  width: fit-content;
  height: 32px;
  text-transform: capitalize;
  padding: 20px 25px !important;
  font-family: "Honeywell Sans Web";
  font-size: 15px;
}

li.MuiBreadcrumbs-li {
  cursor: pointer;
}

.errorComp.showDetailsDataError .DataErrorBtn:hover {
  background-color: rgb(100, 195, 255);
}

.search-close {
  background-color: #202020b5;
  border-radius: 5px;
  position: absolute;
  right: 17%;
  top: 20%;
  color: #fff;
  cursor: pointer;
}

.search-close-firmware {
  background-color: #202020b5;
  border-radius: 5px;
  position: absolute;
  right: -30%;
  top: 20%;
  color: #fff;
  cursor: pointer;
}

.filter-drawer .MuiDrawer-paper {
  background: rgba(39, 39, 39, 1);
  padding: 32px;
  font-family: "Honeywell Sans Web";
  width: 400px;
 
  .title {
    color: var(--typography-dark-base-default-f-0-f-0-f-0, #F0F0F0);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Honeywell Sans Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    /* 150% */
    letter-spacing: 2.5px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .type-name {
    color: var(--typography-dark-base-default-f-0-f-0-f-0, #F0F0F0);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/ Body 1 (Bold) */
    font-family: Honeywell Sans Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    margin-bottom: 8px;
  }
  .type-name.status {
    margin-top: 30px;
  }
  .MuiButtonBase-root {
    color: rgba(55, 163, 248, 1) !important;
  }

  .MuiFormControlLabel-label {
    color: var(--typography-dark-base-default-f-0-f-0-f-0, #F0F0F0);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/ Body 1 */
    font-family: Honeywell Sans Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
  }

  .MuiFormControlLabel-root {
    margin-left: 0px;
  }

  .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 8px 8px 8px 0px;
  }

  .date-picker {
    width: max-content;
    margin-left: 30px;
   
.Mui-disabled{
  cursor: not-allowed;
}
    .MuiOutlinedInput-input {
      padding: 8.5px 8px 8.5px 16px;
      -webkit-text-fill-color: #d0d0d0 !important;
    }

    .MuiButtonBase-root {
      color: #D0D0D0 !important;
    }
  }

  .divider {
    margin: 16px -32px;
    border: 1px solid var(--greyscale-grey-n-6202020, #202020)
  }
  .cancel {
    display: flex;
    padding: 6px 10px;
    margin: 0px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-weight: 700;
    font-family: Honeywell Sans Web;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: none; 
    color: #64C3FF;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
.MuiInputLabel-formControl{
  color: #d0d0d0;
}

.reset-filter{
  color: var(--typography-dark-blue-64-c-3-ff, #64C3FF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  
  /* Buttons & Links/Secondary & Tertiary - Regular & Small */
  font-family: Honeywell Sans Web;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.5px;}

  .filter-headar {
    z-index: 9999999999;
    display: flex;
    position: fixed;
    height: 40px;
    background-color: #272727;
    padding: 20px 30px 40px 30px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 351px;
}

  .buttons-container {
    position: fixed;
    bottom: 0px;
    background: #272727;
    padding: 15px 45px 15px 36px;
    margin: -20px 0px -20px -32px;
    border-top: 1px solid #ffffff26;

    /* display: flex;
    justify-content: space-between;
    text-align: center;
    text-transform: capitalize;
margin-top: 8px;
position: absolute;
bottom: 20px; */
    .cancel {
      display: flex;
      padding: 6px 24px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-weight: 700;
      font-family: Honeywell Sans Web;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: none; 
      color: #64C3FF;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    
    .confirm {
      border-radius: 4px;
      background: var(--component-dark-buttons-regular-primary-outline, #64C3FF);
      text-align: center;
      /* Buttons & Links/Primary - Regular & Small */
      font-family: Honeywell Sans Web;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: 0.5px;

text-transform: none;      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.30);
      color: #303030 !important;
      display: flex;
      padding: 6px 30px !important;
      justify-content: center;
      align-items: center;
      width: auto;
    }
  }
  
}
.filter-btn{
  border-radius: 4px !important;
border: 1px solid var(--greyscale-grey-n-1707070, #707070) !important;
background: var(--greyscale-grey-n-1707070, #707070) !important;
font-family: Honeywell Sans Web !important;
font-size: 14px !important;
font-weight: 600 !important;
line-height: 20px !important;
letter-spacing: 0.5px !important;
text-transform: capitalize !important;
box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.30) !important;
display: inline-flex;
padding: 4px 24px 4px 16px !important;
justify-content: center;
height: 36px;
align-items: center;
gap: 8px;
width: fit-content;
}
.uploaded-by-select .MuiSelect-icon{
  fill:#d0d0d0
}
.Mui-disabled.MuiPickersDay-dayWithMargin{
  text-decoration: line-through;
}
.no-records-found {
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
}

/* added*/

.box {
  border: 2px solid #505050;
  padding: 1px 16px 0px 14px;
  border-radius: 5px;
  margin-left: 7px;
}

/* added*/
.checklist {
  font-family: "Honeywell Sans Web";


}

/* added */
.DropDowntitle {
  font-size: 16px;
  font-family: "Honeywell Sans Web";
  padding-left: 15px;
  color: #d0d0d0;

}

/* added */
.checkboxes {
  height: 15px;
  width: 39px;
  margin-top: 3px;
  color: #d0d0d0 !important;
}

/* added */
.span-item {
  margin-top: 2px !important;
  color: #d0d0d0;
}

/* added */
.css-6hp17o-MuiList-root-MuiMenu-list {
  background: #303030;
}

.CircularprogressAmi {
  top: 0px;
  bottom: 0px;
  position: absolute;
  left: 0px;
  right: 0px;
}

.css-10hburv-MuiTypography-root {
  margin: 0;
  font-family: 'Honeywell Sans Web' !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}

/* added */
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  color: #d0d0d0 !important;
  font-size: 16;
  font-family: "Honeywell Sans Web" !important;
  padding-left: 15;
}

.device-pagination .total-items {
  margin-left: 18px;
  font-size: 14px;
  font-family: "Honeywell Sans Web";
  line-height: 20px;
}

.devices .MuiPaginationItem-circular {
  color: #fff;
  font-weight: 200;
}

.device-pagination .MuiButtonBase-root {
  color: #d0d0d0;
}

.device-pagination .Mui-disabled {
  color: #808080;
  opacity: 1;
}

.device-pagination .Mui-selected {
  border-bottom: 4px solid #1792e5;
  border-radius: 2px;
  background-color: transparent;
  min-width: 25px;
}

.device-pagination .goTo {
  font-size: 14px;
  line-height: 20px;
  font-family: "Honeywell Sans Web";
}

.MuiTableSortLabel-root {
  text-transform: uppercase;
}

.device-pagination .MuiInputBase-root {
  width: 56px;
  height: 40px;

  /* Greyscale/Grey n3 505050 */

  background: #505050;
  /* Dark/Shadow_-1dp (inner_shadow_1) */

  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-size: 14px;
  color: #f0f0f0;
  line-height: 20px;
  margin-right: 24px;
  margin-left: 8px;
}

@media screen and (max-width: 660px) {
  .devices label {
    margin-left: 0%;
  }
}

.device-pagination p {
  margin: 0 !important;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  margin-top: 30px;
}

.button-container.details-data {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  margin-top: 25px;
  padding-bottom: 20px;
}

/********** Search Button CSS ********/
.searchBox {
  background: #505050;
  width: 330px;
  height: 36px;
  border: none;
  border-radius: 4px;
  margin-left: 16px;
  color: #d0d0d0 !important;
  font-family: Honeywell Sans Web;
  font-size: 14px;
  font-weight: 500;
  padding-left: 30px;
}

.searchSection {
  padding: 15px 10px;
}

.searchBtn:hover {
  border: 1px solid #ffffff26 !important;
}

.searchBoxComponent {
  background: #272727!important;
  position: relative;
  text-align: left;
}

.searchBox input {
  height: 15px !important;
}

.searchBtn {
  background-color: #505050 !important;
  border-radius: 0px !important;
  border-radius: 5px !important;
  margin-left: 9px !important;
  margin-top: -2px;
  height: 36px;
}

/********** Search Button CSS End ********/

.button-container button {
  font-family: Honeywell Sans Web;

  background: #272727;
  border: 1.5px solid #64c3ff;
  color: #64c3ff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: 87px;
  height: 32px;
  margin-right: 12px;
}

#tableTitle {
  background-color: #353535;
}

.device-filters.MuiFormControl-root .MuiSvgIcon-root {
  color: #f0f0f0;
}

.device-filter-input .MuiOutlinedInput-root,
.device-filter-input .MuiInputLabel-root {
  color: #f0f0f0;
  padding: 0px !important;
}

.device-filter-input .MuiAutocomplete-input {
  padding: 0 !important;
  min-height: 32px;
}

.device-filter-input .MuiAutocomplete-root label {
  top: -5px;
}

.MuiSnackbar-root {
  background: #f0f0f0;

  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: #303030;
  width: 350px;
  justify-content: left !important;
  padding-left: 18px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Honeywell Sans Web";
  height: 60px;
}

.MuiOutlinedInput-root,
.MuiInputLabel-root {
  font-family: Honeywell Sans Web !important;
}

.device-pagination .MuiTablePagination-actions .MuiButtonBase-root.Mui-disabled {
  color: #808080;
}

.device-pagination .MuiInputBase-root .MuiSvgIcon-root {
  color: #f0f0f0;
}

.button-container button:hover {
  color: #303030;
  background: #64c3ff;
}

.refresh-btn {
  color: #303030 !important;
  font-weight: 600;
}